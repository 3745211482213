import { useState } from "react";
import { ActionFunctionArgs, redirect } from "@remix-run/cloudflare";
import { Form, Link } from "@remix-run/react";
import { eq } from "drizzle-orm";
import { drizzle } from "drizzle-orm/d1";

import { initializeLucia, verifyPassword } from "~/db/models/auth.server";
import * as schema from "~/db/schema.server";
import { Button } from "~/ui/Button";
import { Input } from "~/ui/Input";
import { dayjs } from "~/utils/dayjs";
import { badRequest } from "~/utils/response.server";

export default function Login() {
  const [email, setEmail] = useState<string>("");
  return (
    <Form method="POST" className="flex flex-col gap-4 max-w-sm w-full mx-auto mt-4">
      <input type="hidden" name="timezone" value={dayjs.tz.guess()} />
      <h2 className="font-title text-xl">Lights out and away we go!</h2>
      <label className="flex flex-col gap-1" htmlFor="email">
        e-mail
        <Input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          required
          onChange={(evt) => setEmail(evt.target.value)}
          value={email}
        />
      </label>
      <label className="flex flex-col gap-1" htmlFor="password">
        <div className="flex justify-between items-center">
          password
          <Link to="/forgot" state={{ email }} className="text-subtle text-xs hover:underline">
            Forgot your password?
          </Link>
        </div>
        <Input type="password" name="password" id="password" placeholder="Password" required />
      </label>
      <div className="flex flex-col items-center gap-y-2">
        <Button type="submit">Login</Button>
        <Link className="hover:underline" to="/signup">
          Create an account
        </Link>
      </div>
    </Form>
  );
}

export async function action({ request, context }: ActionFunctionArgs) {
  const lucia = initializeLucia(context);
  const db = drizzle(context.cloudflare.env.DB, { schema, logger: false });

  const body = await request.formData();

  const email = body.get("email") as string;
  const password = body.get("password") as string;

  const existingUser = await db.query.user.findFirst({
    where: (user, { eq }) => eq(user.email, email),
  });

  const INVALID_EMAIL_OR_PASSWORD_ERROR = { message: "Invalid email or password" };

  if (!existingUser) {
    throw badRequest(INVALID_EMAIL_OR_PASSWORD_ERROR);
  }

  const isPasswordValid = await verifyPassword(existingUser.passwordHash, password);

  if (!isPasswordValid) {
    throw badRequest(INVALID_EMAIL_OR_PASSWORD_ERROR);
  }

  await db
    .update(schema.user)
    .set({ timezone: body.get("timezone") as string })
    .where(eq(schema.user.id, existingUser.id));

  const session = await lucia.createSession(existingUser.id, {});
  const sessionCookie = lucia.createSessionCookie(session.id);

  return redirect("/", {
    headers: {
      "Set-Cookie": sessionCookie.serialize(),
    },
  });
}
